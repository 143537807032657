//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, } from 'vuex';

export default {
  name: 'Home',
  head () {
    return {
      title: 'Live & Online Continuing Education for PTs, OTs and SLPs',
      description: 'National leader of continuing education for therapists. In-person, live streaming, live webinars, online videos, and online text courses offering CEUs for PTs, OTs, and SLPs',
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: 'Live & Online Continuing Education for PTs, OTs and SLPs'
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: 'National leader of continuing education for therapists. In-person, live streaming, live webinars, online videos, and online text courses offering CEUs for PTs, OTs, and SLPs'
        },
        {
          hid: 'description',
          name: 'description',
          content: 'National leader of continuing education for therapists. In-person, live streaming, live webinars, online videos, and online text courses offering CEUs for PTs, OTs, and SLPs'
        }
      ]
    };
  },
  data () {
    return {
      imagePath: 'https://summitproedu.imgix.net/landing-pages/pt-phippf.jpg',
      testImage: 'https://summitproedu.imgix.net/redesign-assets/new-heros/HERO_SNOW_Homepage.jpg',
      newYearImage: 'https://summitproedu.imgix.net/redesign-assets/new-heros/new_year_sale_2025_v2.png',
      showNewYearImage: false,
      bannerText: '',
      discountCode: '',
      discountText: '',
      courses: [],
      videoFilter: {
        'searchText': '',
        'profession': [],
        'state': [],
        'format': ['VIDEO'],
        'topic': [],
        'setting': [],
        'credits': [],
        'limit': 19,
      },
      livestreamFilter: {
        'searchText': '',
        'profession': [],
        'state': [],
        'format': ['LIVESTREAM'],
        'topic': [],
        'setting': [],
        'credits': [],
        'limit': 19,
      },
      webinarFilter: {
        'searchText': '',
        'profession': [],
        'state': [],
        'format': ['WEBINAR'],
        'topic': [],
        'setting': [],
        'credits': [],
        'limit': 19,
      },
      podcastFilter: {
        'searchText': '',
        'profession': [],
        'state': [],
        'format': ['PODCAST'],
        'topic': [],
        'setting': [],
        'credits': [],
        'limit': 19,
      },
    }
  },
  created(){
    this.checkDate();
  },
  async mounted () {
    this.setPage(this.PageType.COURSES);
    this.courses = await this.$axios.$get('/course/layout');
      },
  computed: {
    ...mapGetters('search', [
      'state', 'profession', 'setting', 'selected', 'page', 'PageType'
    ]),
    getSelectedClasses () {
      return pageType => this.page === pageType ? 'border-b-4 border-primary-140' : null;
    },
  },
  methods: {
    ...mapActions('search', [
      'setProfession',
      'setFilter',
      'applyFilters',
      'setPage'
    ]),
    checkDate() {
      const currentDate = new Date();
      const endDate = new Date('2025-02-01T00:00:00'); // Midnight of 1/31/2025
      this.showNewYearImage = currentDate < endDate;
    },
    goToSearch () {
      this.$router.push('/courses');
    },
    updateFilters (type, selected) {
      this.setFilter(selected);
    },
    goToPage (page) {
      this.$router.push(page);
    },

},
};
